import { useContext, createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const authToken = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken || !user) {
      navigate("/login");
    }
  }, [authToken]);
  return (
    <AuthContext.Provider value={{ authToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
