import axios from "axios";
import qs from "qs";

// const ENABLE_API_LOGS = __DEV__;
const ENABLE_API_LOGS = false;

export const logRequest = ({ config, response }) => {
  // Request
  console.groupCollapsed("request");
  // console.log(
  //   "data: ",
  //   typeof config?.data === "string" ? JSON.parse(config?.data) : config?.data
  // );
  // console.log("params: ", config?.params);
  // console.log("headers: ", config?.headers);
  console.groupEnd();

  // Response
  console.groupCollapsed("response");
  // console.log("data: ", response?.data);
  // console.log("status: ", response?.status);
  console.groupEnd();
};

export const logResponse = (response) => {
  if (!ENABLE_API_LOGS) {
    return;
  }

  console.groupCollapsed(
    `%c[API - ${response?.config?.method}] response: ${response?.config?.url}`,
    `color: #6ECDA5`
  );

  logRequest({ config: response?.config, response });

  console.groupEnd();
};

export const logError = (error) => {
  if (!ENABLE_API_LOGS) {
    return;
  }

  console.groupCollapsed(
    `%c[API - ${error?.config?.method}] error: ${error?.config?.url}`,
    `color: #EE3024`
  );

  logRequest({ config: error.config, response: error.response });

  // console.log("response status: ", error.response?.status);

  console.groupEnd();
};

const authToken = localStorage.getItem("token");
const user = localStorage.getItem("user");

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,

  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: "comma" });
  },
});

if (authToken) {
  api.defaults.headers.common.Authorization = `Bearer ${authToken}`;
}

if (user) {
  api.defaults.headers.common.user = user;
}

api.interceptors.response.use(
  (response) => {
    logResponse(response);
    return response;
  },
  (err) => {
    if (err?.response?.status === 403) {
      localStorage.removeItem("token");
    }
    return Promise.reject(err);
  }
);

export { api as API };
export default api;
